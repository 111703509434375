import React from 'react'
import { parseQuery } from "../../../../base/hooks/useQueryString";
import Icon from '../../../../base/components/Icon';
import {SORT_KEY, SORT_TYPE} from "../../../../base/constants/query";
import {USERS_ROLE_LABEL} from "../../../../base/constants/users";
import {formatDate} from "../../../../base/helpers/formatDate";

const DESC_SORT = 'desc';
const DESC_SORT_ICON = 'sortDesc';
const ASC_SORT_ICON = 'sortAsc';
const SORT_DEFAULT_ICON = 'sortDefault';

export default function UsersTableStructure(location, decorateText) {
    const infoQuery = parseQuery(location.search);
    function getSortIcon(cellName) {
        if (infoQuery[SORT_KEY] === cellName) {
            return infoQuery[SORT_TYPE] === DESC_SORT ? ASC_SORT_ICON : DESC_SORT_ICON;
        }
        return SORT_DEFAULT_ICON;
    }

    return [
        {
            dataField: "firstName",
            sort: true,
            headerFormatter: () => (<>First name<Icon icon={getSortIcon('firstName')} /></>),
            formatter: (cellContent, user) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name" {...decorateText(user.firstName)} />
                    </section>
                )
            }
        },
        {
            dataField: "lastName",
            sort: true,
            headerFormatter: () => (<>Last Name<Icon icon={getSortIcon('lastName')} /></>),
            formatter: (cellContent, user) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name"  {...decorateText(user.lastName)} />
                    </section>
                )
            }
        },
        {
            dataField: "email",
            sort: true,
            headerFormatter: () => (<>Email address <Icon icon={getSortIcon('email')} /></>),
            formatter: (cellContent, user) => {
                return <span {...decorateText(user.email)} />
            }
        },
        {
            headerFormatter: () => (<>Phone number</>),
            dataField: "phoneNumber",
            sort: false,
            formatter: (cellContent) => cellContent && ''
        },
        {
            headerFormatter: () => (<>Date of registration <Icon icon={getSortIcon('registeredAt')} /></>),
            dataField: "registeredAt",
            sort: true,
            formatter: (cellContent) => formatDate(cellContent)
        },
        {
            dataField: "userRole",
            sort: false,
            headerFormatter: () => (<>User Role</>),
            formatter: (cellContent, user) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className="tutee-list--list__name">
                            {USERS_ROLE_LABEL[user.userRole]}
                        </span>
                    </section>
                )
            }
        },
        {
            dataField: "status",
            sort: false,
            headerFormatter: () => (<>Status</>),
            formatter: (cellContent, user) => {
                return (
                    <section className="d-flex flex-row align-items-center">
                        <span className={`tutee-list--list__name ${user.status}`}>
                            {user.status}
                        </span>
                    </section>
                )
            }
        }
    ]
}